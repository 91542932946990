// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".photo-uploader__error--lpVhb{color:#ad0008;font-size:12px;padding-top:5px;text-align:left}", "",{"version":3,"sources":["webpack://./assets/src/js/components/common/ui/form/photo-uploader.vue","webpack://./assets/src/scss/base/_variables.scss"],"names":[],"mappings":"AAEA,8BACE,aCsCS,CDrCT,cAAA,CACA,eAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "photo-uploader__error--lpVhb"
};
module.exports = ___CSS_LOADER_EXPORT___;
